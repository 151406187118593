export function Github({ className }: { className?: string }) {
  return (
    <svg
      width="18"
      height="18"
      viewBox="0 0 18 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <g clipPath="url(#clip0_293_6601)">
        <path
          d="M9 0C4.0567 0 0 4.0567 0 9C0 13.2183 2.95999 17.0502 6.89062 18V15.0224C6.50116 15.1077 6.14273 15.1102 5.74626 14.9933C5.21439 14.8363 4.78221 14.482 4.46141 13.9418C4.25693 13.5968 3.89452 13.2227 3.51645 13.2502L3.42375 12.1996C4.24127 12.1296 4.94852 12.6977 5.36833 13.403C5.55482 13.7167 5.76947 13.9006 6.04495 13.9819C6.31123 14.0603 6.59715 14.0227 6.93031 13.905C7.01395 13.238 7.3195 12.9883 7.55035 12.6367V12.6362C5.20615 12.2866 4.2719 11.0429 3.90111 10.0613C3.40974 8.75789 3.67342 7.12958 4.5423 6.10057C4.55919 6.08052 4.58968 6.02806 4.57787 5.99139C4.17947 4.78798 4.66493 3.79248 4.68279 3.68687C5.14256 3.82283 5.21727 3.55009 6.67996 4.43875L6.93279 4.59064C7.03853 4.65367 7.00529 4.61769 7.11104 4.60973C7.72174 4.44383 8.36554 4.35223 8.99986 4.34399C9.63899 4.35223 10.2787 4.44383 10.9146 4.61646L10.9965 4.62469C10.9893 4.6236 11.0187 4.61948 11.068 4.59009C12.8952 3.48335 12.8296 3.84508 13.3195 3.68578C13.3373 3.79152 13.8163 4.80322 13.4221 5.99139C13.369 6.15509 15.0062 7.65431 14.0988 10.0609C13.728 11.0429 12.7939 12.2866 10.4496 12.6362V12.6367C10.7501 13.0947 11.1112 13.3384 11.1092 14.2832V18C15.04 17.0502 17.9999 13.2183 17.9999 9C18 4.0567 13.9433 0 9 0V0Z"
          fill="inherit"
        />
      </g>
      <defs>
        <clipPath id="clip0_293_6601">
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
